.address-autocomplete {
    position: relative;
  }
  
  .suggestions-dropdown {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    z-index: 10;
    list-style: none;
    padding: 0;
    margin-top: 2px;
  }
  
  .suggestions-dropdown li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .suggestions-dropdown li:last-child {
    border-bottom: none;
  }
  
  .suggestions-dropdown li:hover {
    background-color: #f5f5f5;
  }
  